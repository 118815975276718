import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "./VideoOverlay.less"
import LazyWrapper from "./LazyWrapper"

const VideoOverlay = ({ source, placeholder, mask, ...otherProps }) => {
  const videoRef = useRef()
  const cover = useMemo(() => {
    if (typeof placeholder === "string") {
      return <img src={placeholder} alt={cover} />
    }

    return placeholder
  }, [placeholder])

  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoDom, setVideoDom] = useState(null)

  const { style, className } = otherProps
  let finalStyle = {
    ...(style || {}),
    WebkitMaskImage: mask,
    maskImage: mask,
  }
  let finalClassName = className ?? "" + "video-overlay"

  const onVideoPlay = () => {
    setVideoPlaying(true)
  }

  const bindVideoEvents = useCallback(() => {
    videoDom && videoDom.addEventListener("play", onVideoPlay)
  }, [videoDom])

  const unbindVideoEvents = useCallback(() => {
    videoDom && videoDom.removeEventListener("play", onVideoPlay)
  }, [videoDom])

  useEffect(() => {
    if (videoDom) {
      bindVideoEvents()

      setTimeout(() => {
        videoDom.play()
      }, 300)
    }

    return () => {
      if (videoDom) {
        unbindVideoEvents()
      }
    }
  }, [videoDom])

  return (
    <div {...otherProps} style={finalStyle} className={finalClassName}>
      <LazyWrapper>
        <video
          playsInline={true}
          ref={el => {
            setVideoDom(el)
          }}
          src={source}
          controls={false}
          autoPlay={false}
          muted={true}
          loop={true}
        />
      </LazyWrapper>
      <div className={`cover ${videoPlaying && "hidden"}`}>{cover}</div>
    </div>
  )
}

export default VideoOverlay
